<template>
	<v-breadcrumbs class="py-0" :items="items">
		<template v-slot:divider>/</template>
	</v-breadcrumbs>
</template>

<script>
export default {
	name: "Breadcrumbs",
	data: () => ({
		items: [],
	}),
	methods: {
		atualizar(to) {
			this.items = to.meta.breadcrumb;
		},
	},
	watch: {
		$route(to) {
			this.atualizar(to);
		},
	},
	created() {
		this.atualizar(this.$router.history.current);
	},
};
</script>